import axios from 'axios';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PORT ? `:${process.env.REACT_APP_BACKEND_PORT}` : ''}${process.env.NODE_ENV === 'development' ? '/api' : ''}`,
  timeout: 5000
});


const getHeaders = () => {
    return {
        "Authorization": Cookies.get('accessToken'),
        "Content-Type": 'application/json'
    }
};

export const fetchData = async (endpoint, params) => {
    try {
        const response = await instance.get(endpoint, {params: params, headers: getHeaders()});
        return { status: response.status, data: response.data };
    } 
    catch(error) {
        throw error;
    }
}

export const postData = async(endpoint, body) => {
    try {
        const response = await instance.post(endpoint, body, { headers: getHeaders() });
        return { status: response.status, data: response.data };
    }
    catch (error) {
        throw error;
    }
}

export const patchData = async(endpoint, body) => {
    try {
        const response = await instance.patch(endpoint, body, { headers: getHeaders() });
        return { status: response.status, data: response.data };
    }
    catch (error) {
        throw error;
    }
}